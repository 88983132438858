<template>
<div class="">
    <!-- <Index role="doctor" /> -->
    <Diary role="hcp"/>
    <!-- <Diary role="doctor"/> -->
</div>
</template>


<script>
// import Index from '../common/indexlayout.vue'
import Diary from '../common/diarylayout.vue'
export default {

  name: 'app',
  components: {
    // Index,
    Diary
  },


}
</script>